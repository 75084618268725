const config = {
  api: {
    webKeyAPI: {
      name: "/authentication/webKeyValidation",
      type: "POST",
    },
    fileUpload: {
      name: "/fileUpload",
      type: "POST",
    },
    refreshtoken: {
      name: "/authentication/refreshtoken",
      type: "GET",
    },
    fileDelete: {
      name: "/fileUpload/delete",
      type: "POST",
    },
    eventTrackingAPI: {
      name: "/eventTracking/logEvent",
      type: "POST",
    },
    getConfigAPI: {
      name: "/getConfig",
      type: "GET",
    },
    documentsUpload: {
      name: "/documents/uploadDocuments",
      type: "POST",
    },
    maintenanceAPI: {
      name: "/maintenance",
      type: "GET",
    },
  },
  validationMsg: {
    invalidEntry: "Invalid entry",
    displayContact: "Invalid entry - contact the sender listed in your email",
    futureDate: "Cannot be Future Date",
    wrongDateFormat: "Must be Format MM/DD/YYYY",
    fieldsRequired: "Fields Are Required",
    fName:
      "Can only contain letters, apostrophes, and hyphens and be at least 2 characters long",
    lName:
      "Can only contain letters, apostrophes, and hyphens and be at least 2 characters long",
    phone:
      "Enter phone in one of the following formats: 555-333-1212 or 5553331212",
    phoneExt: "Enter a valid phone extension in the following format: 12345",
    email: "Enter email in the following format: username@domain.com",
    cEmail: "Enter email in the following format: username@domain.com",
    matchEmailErr: "Confirm emails match",
  },
  content: {
    headerText: "Patient Advocacy Portal",
    whoIsDataiSightQ: "Who is Data iSight?",
    whoIsDataiSightA: `We are a service your insurance carrier has engaged to assist
        with pricing out-of-network claims. When you are seen by an
        out-of-network healthcare provider, we provide fair and
        transparent pricing for the bills sent to your healthplan. When
        you (or a member of your healthplan) receive a balance bill, we
        help to negotiate on your behalf with the healthcare provider.`,
  },
  uploadSteps: ["Member Balance Bill Upload", "Explanation of Benefits Upload"],
};
export default config;
