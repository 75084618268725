import React, { lazy, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../asserts/images/dis-logo-300.gif";
import {
  isEmpty,
  isLastNameValid,
  isOnlyLettersApostrophesHyphens,
  isPhoneExtValid,
  isPhoneValid,
  isValidEmail,
} from "../common/validator";
import { RBPPortalContext } from "../context/RBPPortalContext";

export const Footer = lazy(() => import("../layout/Footer"));

const configValidators = {
  fName: (value) => isOnlyLettersApostrophesHyphens(value),
  lName: (value) => isLastNameValid(value),
  phone: (value) => isPhoneValid(value),
  phoneExt: (value) => isPhoneExtValid(value),
  email: (value) => isValidEmail(value),
  cEmail: (value) => isValidEmail(value),
};
const ModalBox = lazy(() => import("../components/common/ModalBox"));
const PreventTransitionPrompt = lazy(() =>
  import("../components/PreventTransitionPrompt")
);
const formFieldItem = {
  value: "",
  inValid: false,
  error: "",
};

const initialState = {
  fName: {
    ...formFieldItem,
    isRequired: true,
  },
  lName: {
    ...formFieldItem,
    isRequired: true,
  },
  phone: {
    ...formFieldItem,
    isRequired: true,
  },
  phoneExt: {
    ...formFieldItem,
    isRequired: false,
  },
  email: {
    ...formFieldItem,
    isRequired: true,
  },
  cEmail: {
    ...formFieldItem,
    isRequired: true,
  },
};

const ContactInfo = (props) => {
  const configData = React.configData;
  const apiClient = React.apiClient;
  const navigate = useNavigate();
  const location = useLocation();
  var initialLoginStateValue = {};
  const { dispatch } = React.useContext(RBPPortalContext);
  var state = location.state || {};
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(false);
  const { fName, lName, phone, phoneExt, email, cEmail } = formData;

  const firstFieldRef = useRef(null);
  const [cancelBtnClicked, setCancelBtnClicked] = useState(false)
  const [refreshBtnClicked, setRefreshBtnClicked] = useState(false)
  const [backBtnClicked, setBackBtnClicked] = useState(false)
  useEffect(() => {
    var isReload = sessionStorage.getItem("isReload");
    if (sessionStorage.contactInfoData) {
      sessionStorage.setItem("isReload", true);
      const contactInfoValue = JSON.parse(sessionStorage.contactInfoData);
      setFormData(contactInfoValue);
    }
    if (sessionStorage.getItem("initialLoginState") === null) {
      sessionStorage.setItem("initialLoginState", JSON.stringify(location.state));
    }
    var isBackBtnClicked = sessionStorage.getItem("isBackBtnClicked");
    if (isReload === "true" && sessionStorage.contactInfoData) {
      const setBtn = true;
      setRefreshBtnClicked(setBtn);
      const contactInfoValue = JSON.parse(sessionStorage.contactInfoData);
      setFormData(contactInfoValue);
      sessionStorage.setItem("isBackBtnClicked", false);
    }
    if (isBackBtnClicked === "true") {
      const setBtn = true;
      setBackBtnClicked(setBtn);
    }
    if (sessionStorage.getItem("isReloadCompleted") === "true") {
      sessionStorage.setItem("isReload", false);
      const setBtn = false;
      setRefreshBtnClicked(setBtn);
    }

    window.scrollTo({ top: 0 });
    const timer = setTimeout(() => {
      if (firstFieldRef.current) {
        firstFieldRef.current.focus();
      }
    })
    return () => {
      clearTimeout(timer);
    }
  }, [])
  const handleChange = (event) => {
    const newFormData = {
      ...formData,
      [event.target.name]: {
        ...formData[event.target.name],
        value: event.target.value,
        error: "",
        inValid: false,
      },
    };
    sessionStorage.setItem("isReload", true);
    sessionStorage.setItem("isReloadCompleted", false);
    sessionStorage.setItem("contactInfoData", JSON.stringify(newFormData));
    setFormData(newFormData);
  };

  window.addEventListener('popstate', function (event) {
    if (event.origin != '/welcome') {
      if (event.type === 'popstate') {
        const setBtn = true;
        setBackBtnClicked(setBtn);
        sessionStorage.setItem("isBackBtnClicked", true);
        sessionStorage.setItem("isReload", false);
        if (sessionStorage.contactInfoData) {
          const contactInfoValue = JSON.parse(sessionStorage.contactInfoData);
          setFormData(contactInfoValue);
        }
        event.preventDefault()
        navigate("/contactInfo");
      }
    }
  });

  const validateFormFields = () => {
    const fields = formData;
    let noErrors = true;
    for (let fieldName in fields) {
      if (isEmpty(fields[fieldName].value) && fields[fieldName].isRequired) {
        fields[fieldName].error = "Required";
        fields[fieldName].inValid = true;
        noErrors = false;
      } else {
        if (!isEmpty(fields[fieldName].value)) {
          const fieldValid = configValidators[fieldName](
            fields[fieldName].value
          );
          if (!fieldValid) {
            fields[fieldName].error = configData.validationMsg[fieldName];
            fields[fieldName].inValid = true;
            noErrors = false;
          }
        }
      }
    }
    if (
      fields.email.value.toLowerCase() !== fields.cEmail.value.toLowerCase()
    ) {
      fields.cEmail.error = configData.validationMsg.matchEmailErr;
      fields.cEmail.inValid = true;
      noErrors = false;
    }
    if (!noErrors) {
      setFormData({ ...fields });
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    if (validateFormFields()) {
      sessionStorage.setItem("isReload", false);
      sessionStorage.setItem("isBackBtnClicked", false);
      var state = JSON.parse(sessionStorage.getItem("initialLoginState"));
      state = { ...state, ...formData }
      navigate("/welcome", { state });
    }
  };

  const logOut = () => {
    const setBtn = true;
    setCancelBtnClicked(setBtn);
  };

  const logOutCancel = () => {
    const setBtn = false;
    setCancelBtnClicked(setBtn);
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.setItem("isReload", false);
    dispatch({
      type: "ReturnToWebKey",
    });
    navigate("/");
  };

  const logOutRefresh = () => {
    const setBtn = false;
    setRefreshBtnClicked(setBtn);
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.setItem("isReload", false);
    sessionStorage.setItem("isReloadCompleted", true);
    setFormData(initialState);
    sessionStorage.setItem("contactInfoData", JSON.stringify(initialState));
  };


  const logOutBack = () => {
    const setBtn = false;
    setBackBtnClicked(setBtn);
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.setItem("isBackBtnClicked", false);
    dispatch({
      type: "ReturnToWebKey",
    });
    navigate("/");
  };

  return (
    <>
      {error && (
        <Alert variant="danger" role="alert">
          <i className="fa fa-info-circle"> </i> {error}
        </Alert>
      )}
      <header className="container text-center mt-0 mt-md-3 mt-lg-4 mt-xl-5 p-3">
        <img src={logo} alt="Data iSight" width="360" height="auto"></img>
      </header>
      <div className="card-signin bg-white shadow rounded mb-5 p-4">
        <h1 className="h4"> Your Contact Information </h1>
        <hr></hr>
        <Form noValidate className="small">
          <Form.Group controlId="firstname" className="mb-3">
            <Form.Label className="required">First Name</Form.Label>
            <Form.Control
              type="text"
              value={fName.value}
              isInvalid={fName.inValid}
              onChange={handleChange}
              autoFocus={true}
              ref={firstFieldRef}
              name="fName"
              className="form-control-sm"
              maxLength="20"
              aria-label="fName"
              data-testid="fName"
            />
            <Form.Control.Feedback type="invalid" data-testid="feedback">
              {fName.error}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lastname" className="mb-3">
            <Form.Label className="required">Last Name</Form.Label>
            <Form.Control
              type="text"
              value={lName.value}
              isInvalid={lName.inValid}
              onChange={handleChange}
              autoFocus={false}
              name="lName"
              className="form-control-sm"
              maxLength="30"
              aria-label="lName"
              data-testid="lName"
            />
            <Form.Control.Feedback type="invalid" data-testid="feedback">
              {lName.error}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} lg="8" controlId="phone" className="pr-3">
              <Form.Label className="required">Phone</Form.Label>
              <Form.Control
                type="text"
                value={phone.value}
                isInvalid={phone.inValid}
                onChange={handleChange}
                autoFocus={false}
                name="phone"
                className="form-control-sm"
                data-testid="phone"
              />
              <Form.Control.Feedback type="invalid" data-testid="feedback">
                {phone.error}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="phoneExt" as={Col}>
              <Form.Label lg="2">Ext.</Form.Label>
              <Col lg="12" className="pl-0 pr-1">
                <Form.Control
                  type="text"
                  value={phoneExt.value}
                  isInvalid={phoneExt.inValid}
                  onChange={handleChange}
                  autoFocus={false}
                  name="phoneExt"
                  className="form-control-sm"
                  maxLength="5"
                  data-testid="phoneExt"
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="width180"
                  data-testid="feedback"
                >
                  {phoneExt.error}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label className="required">Email</Form.Label>
            <Form.Control
              value={email.value}
              isInvalid={email.inValid}
              onChange={handleChange}
              autoFocus={false}
              type="email"
              name="email"
              className="form-control-sm"
              maxLength="50"
              data-testid="txtEmail"
            />
            <Form.Control.Feedback type="invalid" data-testid="feedback">
              {email.error}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="confirmEmail" className="mb-3">
            <Form.Label className="required">Confirm Email</Form.Label>
            <Form.Control
              value={cEmail.value}
              isInvalid={cEmail.inValid}
              onChange={handleChange}
              autoFocus={false}
              type="email"
              name="cEmail"
              className="form-control-sm"
              maxLength="50"
              data-testid="txtCemail"
            />
            <Form.Control.Feedback type="invalid" data-testid="feedback">
              {cEmail.error}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="agree" className="mb-3"></Form.Group>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              size="sm"
              onClick={logOut}
              variant="link"
              data-testid="btncancel"
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={handleSubmit}
              className={`btn btn-project`}
              id="button"
              data-testid="btnContactConfirm"
            >
              Continue
            </Button>
          </div>
        </Form>
      </div>
      <ModalBox
        show={cancelBtnClicked}
        type="alert"
        size="md"
        title="Please confirm"
        body="Are you sure you want to leave? You will be required to restart this process in order to upload and submit your documents again."
        confirmation={true}
        cancelButtonText="Remain on this page"
        confirmButtonText="Yes, cancel"
        handleClose={() => setCancelBtnClicked(false)}
        onConfirm={logOutCancel}
      />

      <ModalBox
        show={refreshBtnClicked}
        type="alert"
        size="md"
        title="Please confirm"
        body="Are you sure you want to refresh, you will lose unsaved info"
        confirmation={true}
        confirmButtonText="Reload"
        handleClose={() => { setRefreshBtnClicked(false); }}
        onConfirm={logOutRefresh}
      />

      <ModalBox
        show={backBtnClicked}
        type="alert"
        size="md"
        title="Please confirm"
        body="Are you sure you want to leave? You will be required to restart this process in order to upload and submit your documents again."
        confirmation={true}
        cancelButtonText="Remain on this page"
        confirmButtonText="Yes, cancel"
        handleClose={() => { sessionStorage.setItem("isBackBtnClicked", false); setBackBtnClicked(false); }}
        onConfirm={logOutBack}
      />
      <Footer isValidatedWebKey={false} />
    </>
  );
};

export default ContactInfo;