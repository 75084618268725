import moment from "moment";

export const isFutureDate = (value) => {
  return moment(value.trim(), "MM/DD/YYYY") > moment() ? true : false;
};
export const isValidFile = (value) => {
  const allowedFileType =
    /(\.BMP|\.CSV|\.DOC|\.DOCM|\.DOCX|\.GIF|\.HTM|\.JPG|\.LOG|\.MDI|\.PDF|\.PNG|\.PNG|\.RTF|\.TIF|\.TIFF|\.TXT|\.XLS|\.XLSB|\.XLSM|\.XLSX|\.ZIP|\.JFIF|\.HEIC)$/i;
  return allowedFileType.test(value.toUpperCase()) ? true : false;
};
export const isWebkeyStringValid = (value) => {
  const regex = /[^A-Za-z0-9]/g;
  return regex.test(value);
};
export const hasWrongFormat = (value) => {
  let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return !date_regex.test(value);
};
export const isHeifEncoding = (value) => {
  // expected heif headers collected from https://github.com/strukturag/libheif/issues/83
  const expectedHeader =
    /ftyp(heic|heix|hevc|hevx|heim|heis|hevm|hevs|mif1)/gim;
  return expectedHeader.test(value) ? true : false;
};
export const isOnlyLettersApostrophesHyphens = (value) => {
  return value.match(/^[a-zA-Z/'/-\s]{2,}$/) ? true : false;
};

export const isLastNameValid = (value) => {
  return value.trim().match(/^[a-zA-Z/'/-\s]{2,}$/) ? true : false;
};

export const isPhoneValid = (value) => {
  return value
    .trim()
    .match(
      /^(\d{3}-\d{3}-\d{4}|\d{10})$/
    )
    ? true
    : false;
};

export const isPhoneExtValid = (value) => {
  return value.trim().match(/^\d{0,5}$/) ? true : false;
};

export const isValidEmail = (value) => {
  return value
    .trim()
    .match(/^[a-zA-Z0-9_\-\.]{2,}@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,5})$/)
    ? true
    : false;
};

export const isEmpty = (value) => {
  return value === "" ? true : false;
};

export default {
  isFutureDate,
  isValidFile,
  isWebkeyStringValid,
  hasWrongFormat,
  isHeifEncoding,
  isOnlyLettersApostrophesHyphens,
  isLastNameValid,
  isPhoneValid,
  isValidEmail,
  isEmpty,
};
