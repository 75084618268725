import React, { useEffect, useContext, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { RBPPortalContext } from "../context/RBPPortalContext";
import { Helmet } from "react-helmet";
import ContactInfo from "../components/ContactInfo";
const AnotherTab = lazy(() => import("../views/AnotherTab"));
const WelcomePage = lazy(() => import("../views/WelcomePage"));
const SignInPage = lazy(() => import("../views/SignInPage"));
const IdleUser = lazy(() => import("../components/common/IdleUser"));
function Main() {
  React.useNewTab();
  const configData = React.configData;
  const apiClient = React.apiClient;
  const signedInOtherTab = sessionStorage.getItem("SESloggedIn");
  const { state } = useContext(RBPPortalContext);
  const { isAuthenticated } = state;
  const [clientkeyV2, setClientkeyV2] = useState([]);
  const [clientkeyV3, setClientkeyV3] = useState([]);

  const PublicRoute = () => {
    return signedInOtherTab ? <AnotherTab /> : <Outlet />;
  };

  const PrivateRoute = () => {
    if (signedInOtherTab) return <AnotherTab />;
    return isAuthenticated ? <Outlet /> : <Navigate to="/" replace={true} />;
  };

  /**
   * API call to Node JS to get csrf Token on load
   */
  const getCsrfToken = async () => {
    await apiClient.requestAPI("GET", "/csrf-token");
  };

  useEffect(() => {
    (async () => {
      const apiKeyD = await apiClient.requestAPI(
        configData.api.getConfigAPI.type,
        configData.api.getConfigAPI.name,
        {},
        {}
      );
      if (apiKeyD) {
        sessionStorage.setItem("AppD", apiKeyD.data.REACT_APP_CLIENT_APPD_KEY);
        setClientkeyV2(apiKeyD.data.REACT_APP_CLIENT_KEY_V2);
        setClientkeyV3(apiKeyD.data.REACT_APP_CLIENT_KEY_V3);
      }
    })();
    getCsrfToken();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {sessionStorage.getItem("AppD") ? (
        <Helmet
          encodeSpecialCharacters={true}
          defer={false}
          onChangeClientState={(newState, addedTags, removedTags) => ""}
        >
          <script charset="UTF-8" type="text/javascript">
            {`
              window["adrum-start-time"] = new Date().getTime();
              (function(config){
                config.appKey = sessionStorage.getItem("AppD");
                config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
                config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
                config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
                config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
                config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
                config.maxUrlLength = 512;
              })(window["adrum-config"] || (window["adrum-config"] = {}));
            `}
          </script>
          <script src="//cdn.appdynamics.com/adrum/adrum-22.6.0.3859.js"></script>
        </Helmet>
      ) : (
        ""
      )}
      <Routes>
        <Route element={<PublicRoute />}>
          <Route
            path="/"
            element={
              <SignInPage clientkeyV2={clientkeyV2} clientkeyV3={clientkeyV3} />
            }
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/contactInfo" element={<ContactInfo />} />
          <Route path="/welcome" element={<WelcomePage />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </Suspense>
  );
}
export default Main;
